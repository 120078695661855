import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home/index.vue')
  },
  {
    path: '/productIntroduction',
    name: 'productIntroduction',
    component: () => import('../views/productIntroduction/index.vue')
  },
  {
    path: '/companyprofile',
    name: 'companyprofile',
    component: () => import('../views/companyprofile/index.vue')
  },

  {
    path: '/caseDetail',
    name: 'caseDetail',
    component: () => import('../views/caseDetail/index.vue')
  },
  {
    path: '/payOrder',
    name: 'payOrder',
    component: () => import('../views/payOrder/index.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;
